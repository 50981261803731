export default {
    name: 'Nazwa',
    tel: 'Telefon',
    save: 'Zapisz',
    confirm: 'Potwierdź',
    cancel: 'Anuluj',
    delete: 'Usuń',
    loading: 'Ładowanie...',
    noCoupon: 'Brak kuponów',
    nameEmpty: 'Wprowadź nazwę',
    telInvalid: 'Nieprawidłowy numer telefonu',
    vanCalendar: {
        end: 'Koniec',
        start: 'Start',
        confirm: 'Potwierdź',
        title: 'Kalendarz',
        startEnd: 'Start/Koniec',
        weekdays: ['Nd', 'Pn', 'Wt', 'Śr', 'Cz', 'Pt', 'Sb'],
        monthTitle: (year: number, month: number) => `${year}/${month}`,
        rangePrompt: (maxRange: number) => `Nie wybieraj więcej niż ${maxRange} dni`,
    },
    vanPicker: {
        select: 'Wybierz',
    },
    vanContactCard: {
        addText: 'Dodaj informacje kontaktowe',
    },
    vanContactList: {
        addText: 'Dodaj nowy kontakt',
    },
    vanPagination: {
        prev: 'Poprzedni',
        next: 'Następny',
    },
    vanPullRefresh: {
        pulling: 'Pociągnij, aby odświeżyć...',
        loosing: 'Puść, aby odświeżyć...',
        loading: 'Ładowanie...',
    },
    vanSubmitBar: {
        label: 'Razem:',
    },
    vanCoupon: {
        unlimited: 'Bez limitu',
        discount: (discount: number) => `${discount * 10}% zniżki`,
        condition: (condition: number) => `Minimum ${condition}`,
    },
    vanCouponCell: {
        title: 'Kupon',
        count: (count: number) => `Masz ${count} kuponów`,
    },
    vanCouponList: {
        exchange: 'Wymień',
        close: 'Zamknij',
        enable: 'Dostępny',
        disabled: 'Niedostępny',
        placeholder: 'Kod kuponu',
    },
    vanAddressEdit: {
        area: 'Region',
        postal: 'Kod pocztowy',
        areaEmpty: 'Wybierz region dostawy',
        addressEmpty: 'Adres nie może być pusty',
        postalEmpty: 'Nieprawidłowy kod pocztowy',
        defaultAddress: 'Ustaw jako adres domyślny',
    },
    vanAddressEditDetail: {
        label: 'Adres',
        placeholder: 'Adres',
    },
    vanAddressList: {
        add: 'Dodaj nowy adres',
    },
}