import { getReactVantLangPkg } from "@/lang/react-vant-langs";
import ReactDOM from "react-dom/client";
import { IntlProvider } from 'react-intl';
import { ConfigProvider } from "react-vant";
import App from "./App";
import "./index.css";
import { getPreferredLanguage, messages } from './lang/config';
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);


root.render(
  <IntlProvider messages={messages[getPreferredLanguage()]} locale={getPreferredLanguage()}>
    <ConfigProvider locale={getReactVantLangPkg(getPreferredLanguage())}>
      <App />
    </ConfigProvider>
  </IntlProvider>
);

reportWebVitals();
