import moment from "moment";

const { abs } = Math;
const { isInteger, parseFloat, isNaN } = Number;
const multiple = 1024;

/**
 *
 * @param volume M
 * @param whether 是否需要带单位，默认为true
 * @returns {*}
 */
export const volumeFormat = function (
  volume: number | string,
  whether = true,
  unit = "M"
) {
  const units = unit === "M" ? ["M", "G"] : ["K", "M"];
  if (isNaN(+volume)) return "";
  let result = volume;
  if (typeof result === "string") result = parseFloat(result);
  // 小于 1G
  if (abs(result) < 1024) {
    // 包含小数
    if (!isInteger(result)) result = result?.toFixed(2) || "";
    return result + (whether ? units[0] : "");
  }
  result /= multiple;
  // 转成G包含小数
  if (!isInteger(result)) result = result?.toFixed(2) || "";
  return result + (whether ? units[1] : "");
};

export const volumUnitFormat = function (
  volume: any,
  offerType: string,
  validity: number,
  validityUnit: string,
  buyCountStep = 1,
  needDivide = true
) {
  const multiplier = buyCountStep > 1 ? ` x ${buyCountStep}` : "";

  if (offerType === "MONTHLY") {
    return volumeFormat(volume) + " / Month" + multiplier;
  }

  if (offerType === "CUSTOM" && needDivide) {
    if (validity % 360 === 0) {
      const years = validity / 360;
      return (
        volumeFormat(volume) +
        ` / ${years} Year${years !== 1 ? "s" : ""} ${multiplier}`
      );
    } else if (validity % 30 === 0) {
      const months = validity / 30;
      return (
        volumeFormat(volume) +
        ` / ${months} Month${months !== 1 ? "s" : ""} ${multiplier}`
      );
    } else {
      return (
        volumeFormat(volume) +
        ` / ${validity} Day${validity !== 1 ? "s" : ""} ${multiplier}`
      );
    }
  }

  const formattedVolume = volumeFormat(volume);
  let validityPeriod;

  if (validityUnit === "D") {
    validityPeriod = `${validity || ""} Day${validity !== 1 ? "s" : ""}`;
  } else if (validityUnit === "M") {
    validityPeriod = `${validity || ""} Month${validity !== 1 ? "s" : ""}`;
  } else if (validityUnit === "Y") {
    validityPeriod = `${validity || ""} Year${validity !== 1 ? "s" : ""}`;
  } else {
    validityPeriod = validity || "";
  }

  return `${formattedVolume} / ${validityPeriod}${multiplier}`;
};

export const dateFormat = (
  value: any,
  format = "YYYY-MM-DD HH:mm:ss",
  fallback = "-"
) => {
  if (
    typeof value !== "string" &&
    typeof value !== "number" &&
    !moment.isMoment(value) &&
    !moment.isDate(value)
  ) {
    return fallback;
  }
  if (moment.isMoment(value)) {
    return value.format(format || "YYYY-MM-DD HH:mm:ss");
  }
  return moment(value).format(format || "YYYY-MM-DD HH:mm:ss");
};

/**
 * Calculate the difference between two dates in specified unit
 * @param date1 First date
 * @param date2 Second date (optional, defaults to current date)
 * @param unit Unit of measurement ('Y' for years, 'M' for months, 'D' for days, 'h' for hours, 'm' for minutes, 's' for seconds)
 * @returns number Difference in the specified unit (positive if date1 is after date2, negative if date1 is before date2)
 */
export const dateDiff = (
  date1: string | Date | number,
  date2: string | Date | number = new Date(),
  unit: "Y" | "M" | "D" | "h" | "m" | "s" = "D"
): number => {
  const momentDate1 = moment(date1);
  const momentDate2 = moment(date2);

  if (!momentDate1.isValid() || !momentDate2.isValid()) {
    return 0;
  }

  const unitMap = {
    Y: "years",
    M: "months",
    D: "days",
    h: "hours",
    m: "minutes",
    s: "seconds",
  } as const;

  return momentDate1.diff(momentDate2, unitMap[unit]);
};



/**
 * 获取 URL 中的 query 参数值
 * @param {string} key - 要获取的 query 参数的键
 * @returns {string|null} - 返回 query 参数的值，如果不存在则返回 null
 */
export function getQueryParam(key: string) {
    if (!key) return null;

    const url = window.location.href;
    const isHashRoute = url.includes("#/");

    // 改进 hash 路由参数提取逻辑
    let queryString = isHashRoute
        ? window.location.hash.substring(window.location.hash.indexOf("?") + 1)
        : window.location.search.slice(1);

    const params = new URLSearchParams(queryString);
    return params.get(key);
}


export function changeUrlParam(key: string, value: string) {
    console.log(`${key}, ${value}`)
    if (!key || !value) return;
    const url = window.location.href;
    const isHashRoute = url.includes("#/");
    
    if (url.includes(`${key}=`)) {
        // param existed in current url
        window.location.href = window.location.href.replace(`${key}=${getQueryParam(key)}`, `${key}=${value}`);

    } else {
        // param not existed
        if (isHashRoute) {
            const hashParts = url.split('#/');
            const basePath = hashParts[0] + '#/';
            const routePath = hashParts[1].split('?')[0];
            const queryString = hashParts[1].split('?')[1] || '';
            window.location.href = `${basePath}${routePath}${queryString ? '?' + queryString + '&' : '?'}${key}=${value}`;
        } else {
            window.location.href = `${url}${url.includes('?') ? '&' : '?'}${key}=${value}`;
        }
    }
}
