export default {
    name: 'Nombre',
    tel: 'Teléfono',
    save: 'Guardar',
    confirm: 'Confirmar',
    cancel: 'Cancelar',
    delete: 'Eliminar',
    loading: 'Cargando...',
    noCoupon: 'Sin cupones',
    nameEmpty: 'Por favor ingrese el nombre',
    telInvalid: 'Número de teléfono inválido',
    vanCalendar: {
        end: 'Fin',
        start: 'Inicio',
        confirm: 'Confirmar',
        title: 'Calendario',
        startEnd: 'Inicio/Fin',
        weekdays: ['Dom', 'Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb'],
        monthTitle: (year: number, month: number) => `${year}/${month}`,
        rangePrompt: (maxRange: number) => `No seleccione más de ${maxRange} días`,
    },
    vanPicker: {
        select: 'Seleccionar',
    },
    vanContactCard: {
        addText: 'Añadir información de contacto',
    },
    vanContactList: {
        addText: 'Añadir nuevo contacto',
    },
    vanPagination: {
        prev: 'Anterior',
        next: 'Siguiente',
    },
    vanPullRefresh: {
        pulling: 'Tire para actualizar...',
        loosing: 'Suelte para actualizar...',
        loading: 'Cargando...',
    },
    vanSubmitBar: {
        label: 'Total:',
    },
    vanCoupon: {
        unlimited: 'Ilimitado',
        discount: (discount: number) => `${discount * 10}% de descuento`,
        condition: (condition: number) => `Mínimo ${condition}`,
    },
    vanCouponCell: {
        title: 'Cupón',
        count: (count: number) => `Tiene ${count} cupones`,
    },
    vanCouponList: {
        exchange: 'Intercambiar',
        close: 'Cerrar',
        enable: 'Disponible',
        disabled: 'No disponible',
        placeholder: 'Código de cupón',
    },
    vanAddressEdit: {
        area: 'Región',
        postal: 'Código postal',
        areaEmpty: 'Por favor seleccione una región de envío',
        addressEmpty: 'La dirección no puede estar vacía',
        postalEmpty: 'Código postal inválido',
        defaultAddress: 'Establecer como dirección predeterminada',
    },
    vanAddressEditDetail: {
        label: 'Dirección',
        placeholder: 'Dirección',
    },
    vanAddressList: {
        add: 'Añadir nueva dirección',
    },
}