import en from "react-vant/es/locale/lang/en-US";
import fr from "react-vant/es/locale/lang/fr-FR";
import zh from "react-vant/es/locale/lang/zh-CN";
import ja from "react-vant/es/locale/lang/ja-JP";
import de from "./de"
import es from "./es";
import it from "./it";
import ko from "./ko";
import pl from "./pl";
import ru from "./ru";

// fix: (fr as any) fix typescript error from official react-vant
const langs = { en, fr: fr as any, zh, ja, de, es, it, ko, pl, ru };
export const getReactVantLangPkg = (lang: keyof typeof langs) => {
    return langs[lang];
}
