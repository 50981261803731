// TODO Production environment have not set domain yet

/**
 * ## simboss domain
 * 1. https://client-intl.simboss.com/#/query
 * 2. https://client-intl.ibilling.com.cn/#/query
 * 
 */
export const getDomain = () => {
  const hostname = window.location.hostname;
  if (
    hostname.includes("10.0.11.43") ||
    hostname === "sanjiang-test-domian.test.simboss.com" || // 三匠测试域名
    hostname === "sim.vantruecam.com" ||  // 三匠线上域名(三匠自己nginx 服务转发)
    hostname === "paypal-yuanyuan.simboss.com" // 线上 自定义域名 测试
  )
    return "sanjiang";
  return "default";
};
