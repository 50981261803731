export default {
    name: '이름',
    tel: '전화',
    save: '저장',
    confirm: '확인',
    cancel: '취소',
    delete: '삭제',
    loading: '로딩 중...',
    noCoupon: '쿠폰 없음',
    nameEmpty: '이름을 입력해주세요',
    telInvalid: '잘못된 전화번호',
    vanCalendar: {
        end: '종료',
        start: '시작',
        confirm: '확인',
        title: '달력',
        startEnd: '시작/종료',
        weekdays: ['일', '월', '화', '수', '목', '금', '토'],
        monthTitle: (year: number, month: number) => `${year}년 ${month}월`,
        rangePrompt: (maxRange: number) => `${maxRange}일 이내로 선택해주세요`,
    },
    vanPicker: {
        select: '선택',
    },
    vanContactCard: {
        addText: '연락처 정보 추가',
    },
    vanContactList: {
        addText: '새 연락처 추가',
    },
    vanPagination: {
        prev: '이전',
        next: '다음',
    },
    vanPullRefresh: {
        pulling: '당겨서 새로고침...',
        loosing: '놓아서 새로고침...',
        loading: '로딩 중...',
    },
    vanSubmitBar: {
        label: '합계:',
    },
    vanCoupon: {
        unlimited: '무제한',
        discount: (discount: number) => `${discount * 10}% 할인`,
        condition: (condition: number) => `최소 ${condition}`,
    },
    vanCouponCell: {
        title: '쿠폰',
        count: (count: number) => `${count}개의 쿠폰이 있습니다`,
    },
    vanCouponList: {
        exchange: '교환',
        close: '닫기',
        enable: '사용 가능',
        disabled: '사용 불가',
        placeholder: '쿠폰 코드',
    },
    vanAddressEdit: {
        area: '지역',
        postal: '우편번호',
        areaEmpty: '배송 지역을 선택해주세요',
        addressEmpty: '주소를 입력해주세요',
        postalEmpty: '잘못된 우편번호',
        defaultAddress: '기본 주소로 설정',
    },
    vanAddressEditDetail: {
        label: '주소',
        placeholder: '주소',
    },
    vanAddressList: {
        add: '새 주소 추가',
    },
}