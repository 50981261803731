export default {
    name: 'Nome',
    tel: 'Telefono',
    save: 'Salva',
    confirm: 'Conferma',
    cancel: 'Annulla',
    delete: 'Elimina',
    loading: 'Caricamento...',
    noCoupon: 'Nessun coupon',
    nameEmpty: 'Inserisci il nome',
    telInvalid: 'Numero di telefono non valido',
    vanCalendar: {
        end: 'Fine',
        start: 'Inizio',
        confirm: 'Conferma',
        title: 'Calendario',
        startEnd: 'Inizio/Fine',
        weekdays: ['Dom', 'Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab'],
        monthTitle: (year: number, month: number) => `${year}/${month}`,
        rangePrompt: (maxRange: number) => `Non selezionare più di ${maxRange} giorni`,
    },
    vanPicker: {
        select: 'Seleziona',
    },
    vanContactCard: {
        addText: 'Aggiungi informazioni di contatto',
    },
    vanContactList: {
        addText: 'Aggiungi nuovo contatto',
    },
    vanPagination: {
        prev: 'Precedente',
        next: 'Successivo',
    },
    vanPullRefresh: {
        pulling: 'Trascina per aggiornare...',
        loosing: 'Rilascia per aggiornare...',
        loading: 'Caricamento...',
    },
    vanSubmitBar: {
        label: 'Totale:',
    },
    vanCoupon: {
        unlimited: 'Illimitato',
        discount: (discount: number) => `${discount * 10}% di sconto`,
        condition: (condition: number) => `Minimo ${condition}`,
    },
    vanCouponCell: {
        title: 'Coupon',
        count: (count: number) => `Hai ${count} coupon`,
    },
    vanCouponList: {
        exchange: 'Scambia',
        close: 'Chiudi',
        enable: 'Disponibile',
        disabled: 'Non disponibile',
        placeholder: 'Codice coupon',
    },
    vanAddressEdit: {
        area: 'Regione',
        postal: 'CAP',
        areaEmpty: 'Seleziona una regione di spedizione',
        addressEmpty: "L'indirizzo non può essere vuoto",
        postalEmpty: 'Codice postale non valido',
        defaultAddress: 'Imposta come indirizzo predefinito',
    },
    vanAddressEditDetail: {
        label: 'Indirizzo',
        placeholder: 'Indirizzo',
    },
    vanAddressList: {
        add: 'Aggiungi nuovo indirizzo',
    },
}