import React, { Suspense, useEffect } from "react";
import {
  HashRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { getComponent, getHomePath } from "./utils/routing";
import { loadTheme } from "./utils/theme-loader";
import { Skeleton } from "react-vant";


const App: React.FC = () => {
  useEffect(() => {
    loadTheme();
  }, []);

  return (
    <Router>
      <Suspense
        fallback={<Skeleton title style={{ paddingTop: 50 }} loading={true} row={8}/>}
      >
        <Routes>
          <Route
            path="/query"
            element={React.createElement(getComponent("/query"))}
          />
          <Route
            path="/history"
            element={React.createElement(getComponent("/history"))}
          />
          <Route
            path="/home"
            element={React.createElement(getComponent("/home"))}
          />
          <Route
            path="/plan"
            element={React.createElement(getComponent("/plan"))}
          />
          <Route
            path="/renew"
            element={React.createElement(getComponent("/renew"))}
          />
          <Route
            path="/renew/renew-records"
            element={React.createElement(getComponent("/renew/renew-records"))}
          />
          <Route
            path="/payDetail"
            element={React.createElement(getComponent("/payDetail"))}
          />
          <Route
            path="/done"
            element={React.createElement(getComponent("/done"))}
          />
          {/* <Route
            path="/docs"
            element={React.createElement(getComponent("/docs"))}
          /> */}
          <Route
            path="/docs/:docId"
            element={React.createElement(getComponent("/docs/:docId"))}
          />
          <Route
            path="/"
            element={<Navigate to={getHomePath()} replace />}
          />
          <Route path="*" element={<Navigate to={getHomePath()} replace />} />
        </Routes>
      </Suspense>
    </Router>
  );
};

export default App;
