// src/lang/config.ts
import { createIntl } from "react-intl";
import { changeUrlParam, getQueryParam } from "@/utils/util";

// Import all language messages
import enMessages from "./en.json";
import zhMessages from "./zh.json";
import deMessages from "./de.json";
import frMessages from "./fr.json";
import ruMessages from "./ru.json";
import esMessages from "./es.json";
import jaMessages from "./ja.json";
import koMessages from "./ko.json";
import plMessages from "./pl.json";
import itMessages from "./it.json";

// 语言配置集中管理
export const LOCALE_CONFIG = {
  ENGLISH: {
    key: "en" as const,
    text: "English",
    messages: enMessages,
  },
  CHINESE: {
    key: "zh" as const,
    text: "中文",
    messages: zhMessages,
  },
  GERMAN: {
    key: "de" as const,
    text: "Deutsch",
    messages: deMessages,
  },
  FRENCH: {
    key: "fr" as const,
    text: "Français",
    messages: frMessages,
  },
  RUSSIAN: {
    key: "ru" as const,
    text: "Русский",
    messages: ruMessages,
  },
  SPANISH: {
    key: "es" as const,
    text: "Español",
    messages: esMessages,
  },
  JAPANESE: {
    key: "ja" as const,
    text: "日本語",
    messages: jaMessages,
  },
  KOREAN: {
    key: "ko" as const,
    text: "한국어",
    messages: koMessages,
  },
  POLISH: {
    key: "pl" as const,
    text: "Polski",
    messages: plMessages,
  },
  ITALIAN: {
    key: "it" as const,
    text: "Italiano",
    messages: itMessages,
  },
} as const;

// 类型定义
export type LocaleType =
  (typeof LOCALE_CONFIG)[keyof typeof LOCALE_CONFIG]["key"];
type MessagesType = typeof enMessages;

// 默认语言
const DEFAULT_LANG = LOCALE_CONFIG.ENGLISH.key;

/**
 * 转换消息格式
 * @param messages `{"home.title":{"id":"home.title","defaultMessage":"Home"},"home.noData":{"id":"home.noData","defaultMessage":"NoData"}}`
 * @returns `{"home.title":"Home","home.noData":"NoData"}`
 */
export const transformMessages = (messages: MessagesType) => {
  return Object.keys(messages).reduce((acc, key) => {
    acc[key] = messages[key].defaultMessage;
    return acc;
  }, {} as Record<string, string>);
};

/**
 * 生成所有语言的消息对象
 * @returns `{"en":{"home.title":"Home","home.noData":"NoData"},"zh":{"home.title":"首页","home.noData":"暂无数据"}}`
 */
export const messages = Object.entries(LOCALE_CONFIG).reduce(
  (acc, [_, value]) => {
    acc[value.key] = transformMessages(value.messages);
    return acc;
  },
  {} as Record<LocaleType, Record<string, string>>
);

// 检查是否支持该语言
export const isSupportedLocale = (locale: string) => {
  const langList = Object.values(LOCALE_CONFIG).map(({ key }) => key);
  return langList.includes(locale as LocaleType);
};

// 获取用户首选语言
export const getPreferredLanguage = (): LocaleType => {
  const storedLang = localStorage.getItem(
    "preferredLanguage"
  ) as LocaleType | null;
  const urlLang = getQueryParam("lang") as LocaleType | null;
  const browserLang = navigator.language.split("-")[0] as LocaleType;
  // 按优先级返回：URL > localStorage > 浏览器语言 > 默认语言
  if (urlLang && isSupportedLocale(urlLang)) {
    setLocalLang(urlLang)
    return urlLang;
  }
  if (storedLang && isSupportedLocale(storedLang)) return storedLang;
  if (browserLang && isSupportedLocale(browserLang)) return browserLang;
  return DEFAULT_LANG;
};

// 设置首选语言
const setLocalLang = function (lang: string) {
  console.log("trigger")
  localStorage.setItem("preferredLanguage", lang);
}
export const setPreferredLanguage = (lang: LocaleType) => {
  if (isSupportedLocale(lang)) {
    setLocalLang(lang)
    changeUrlParam("lang", lang);
    window.location.reload();
  }
};

// 获取语言显示列表
export const getLocaleDisplayName = (): {
  text: string;
  value: LocaleType;
}[] => {
  return Object.values(LOCALE_CONFIG).map(({ key, text }) => ({
    text,
    value: key,
  }));
};

// 创建默认的 intl 实例
export const intl = createIntl({
  locale: getPreferredLanguage(),
  messages: messages[getPreferredLanguage()],
});
