export default {
    name: 'Имя',
    tel: 'Телефон',
    save: 'Сохранить',
    confirm: 'Подтвердить',
    cancel: 'Отмена',
    delete: 'Удалить',
    loading: 'Загрузка...',
    noCoupon: 'Нет купонов',
    nameEmpty: 'Пожалуйста, введите имя',
    telInvalid: 'Неверный номер телефона',
    vanCalendar: {
        end: 'Конец',
        start: 'Начало',
        confirm: 'Подтвердить',
        title: 'Календарь',
        startEnd: 'Начало/Конец',
        weekdays: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
        monthTitle: (year: number, month: number) => `${year}/${month}`,
        rangePrompt: (maxRange: number) => `Выберите не более ${maxRange} дней`,
    },
    vanPicker: {
        select: 'Выбрать',
    },
    vanContactCard: {
        addText: 'Добавить контактную информацию',
    },
    vanContactList: {
        addText: 'Добавить новый контакт',
    },
    vanPagination: {
        prev: 'Предыдущий',
        next: 'Следующий',
    },
    vanPullRefresh: {
        pulling: 'Потяните для обновления...',
        loosing: 'Отпустите для обновления...',
        loading: 'Загрузка...',
    },
    vanSubmitBar: {
        label: 'Итого:',
    },
    vanCoupon: {
        unlimited: 'Безлимитный',
        discount: (discount: number) => `Скидка ${discount * 10}%`,
        condition: (condition: number) => `Минимум ${condition}`,
    },
    vanCouponCell: {
        title: 'Купон',
        count: (count: number) => `У вас ${count} купонов`,
    },
    vanCouponList: {
        exchange: 'Обмен',
        close: 'Закрыть',
        enable: 'Доступно',
        disabled: 'Недоступно',
        placeholder: 'Код купона',
    },
    vanAddressEdit: {
        area: 'Регион',
        postal: 'Почтовый индекс',
        areaEmpty: 'Выберите регион доставки',
        addressEmpty: 'Адрес не может быть пустым',
        postalEmpty: 'Неверный почтовый индекс',
        defaultAddress: 'Сделать адресом по умолчанию',
    },
    vanAddressEditDetail: {
        label: 'Адрес',
        placeholder: 'Адрес',
    },
    vanAddressList: {
        add: 'Добавить новый адрес',
    },
}